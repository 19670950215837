<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
  <card>

    <h5 slot="header" class="title">Edit Bet ( Bẻ Cầu )</h5>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsArea" label="Area Name" v-model="adminInput.areaName" name="item-areaName">
      </base-select>
    </div>
    <div class="col-md-8">
      <base-input label="Biệt danh" v-model="adminInput.nickName" name="item-nickname">
      </base-input>
    </div>
    <div class="col-md-8">
      <base-input label="Số dư" v-model="adminInput.balance" name="item-balance">
      </base-input>
    </div>
    <div class="col-md-8">
      <base-input label="Số tiền vào lệnh" v-model="betAmount" name="item-amount">
      </base-input>
    </div>
    <div class="col-md-8">
      <base-button @click=searchUserEdit()>SEARCH</base-button>
      <base-button class="red" color="red" @click="BetBuySell('sell')" :disabled="this.turnPhase === 'WAITING'">SELL</base-button>
      <base-button class="green" color="green" @click="BetBuySell('buy')" :disabled="this.turnPhase === 'WAITING'">BUY</base-button>
      <div class="timeCount" style="dislay: flex">
        <base-input :aria-readonly="true" label="Thời gian" v-model="this.countDown"></base-input>
        <base-input :aria-readonly="true" label="Phiên" v-model="this.turnPhase"></base-input>
      </div>
      </div>





  </card>
  <card>
   <div>
        <!-- <base-input label="Thời gian:" v-model="countDown"></base-input>
      <base-input label="Phiên:" v-model="turnPhase"></base-input> -->


        <h4 style="color:green;font-size:17px"> Volume Buy: ${{ volumeBuy }}</h4>

        <h4 style="color:red;font-size:17px"> Volume Sell: ${{ volumeSell }}</h4>
        <h4>Break Type:
          <span>
            <span v-if="breakType == 'BUY'" class="green">BUY</span>
            <span v-if="breakType == 'SELL'" class="red">SELL</span>
            <span v-if="breakType == 'DRAW'">DRAW</span>
            <span v-if="breakType == 'NONE'">NONE</span>
            <!-- <span v-if="breakType == 'STOP'">STOP</span> -->
          </span>
        </h4>
        <base-button class="green" @click="SelectBetType('BUY')">BUY</base-button>
        <base-button class="red" @click="SelectBetType('SELL')">SELL</base-button>
        <base-button @click="SelectBetType('DRAW')">DRAW</base-button>
        <base-button @click="SelectBetType('NONE')">NONE</base-button>
        <!-- <base-button @click="SelectBetType('STOP')">STOP</base-button> -->
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Area Name</th>
              <th>Nick Name</th>
              <th>Amount</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in paginatedData" :key="index">
              <td>{{ data.areaName }}</td>
              <td>{{ data.nickName }}</td>
              <td>${{ data.amountBet }}</td>
              <!-- <td>{{ data.betType }}</td> -->
              <td v-if="data.betType === 'BUY'" class="green">BUY</td>
              <td v-else-if="data.betType === 'SELL'" class="red">SELL</td>


            </tr>
          </tbody>
        </table>
         <vs-pagination v-model="page" :length="pageCount" @input="updateData"></vs-pagination>
      </div>
  </card>
  <card>
  <div class="">
        <div class="col-md-8">
          <base-select :options="optionsPer" v-model="dataUser.differenceBreakBets" label="Bẻ cầu chênh lệnh">Bẻ cầu chênh
            lệch </base-select>
        </div>
        <div class="col-md-8">
          <base-input label="Số tiền chênh lệnh" v-model="dataUser.differenceOver" name="item-differenceOver">
          </base-input>
        </div>
        <div class="col-md-8">
          <base-input label="Thời gian bẻ lệnh" v-model="dataUser.breakBetsStartSecond" name="item-breakBetsStartSecond">
          </base-input>
        </div>
        <div class="col-md-8">
          <base-select :readonly="true" :options="optionsPer" v-model="dataUser.priceFundOnOff" label="Bẻ cầu theo quỹ">Bẻ
            cầu theo quỹ</base-select>

        </div>
        <div class="col-md-8">
          <base-input label="Số tiền của quỹ" v-model="dataUser.priceFundProfits" name="item-priceFundProfits">
          </base-input>
        </div>
        <div class="col-md-8">
          <base-input label="Giới hận âm của quỹ" v-model="dataUser.priceStopLoss" name="item-priceStopLoss">
          </base-input>
        </div>
        <div class="col-md-8">
          <base-input label="Giá thấp nhất" v-model="dataUser.localChangeLow" name="item-priceStopLoss">
          </base-input>
        </div>
        <div class="col-md-8">
          <base-input label="Giá cao nhất" v-model="dataUser.localChangeHigh" name="item-priceStopLoss">
          </base-input>
        </div>
        <div class="col-md-8">

          <base-input label="Giá thấp nhất ( Kết quả )" v-model="dataUser.localResultLow" name="item-priceStopLoss">
          </base-input>
        </div>
        <div class="col-md-8">
          <base-input label="Giá cao nhất ( Kết quả )" v-model="dataUser.localResultHigh" name="item-priceStopLoss">
          </base-input>
        </div>
      </div>
           <base-button @click=getBreakBetsConfig>ACTIVE</base-button>
      <base-button @click=updateBreakBetsConfig()>SAVE</base-button>


  </card>
  </div>
</template>
<script>
import { BaseTable } from "@/components";
import AuthenticationService from '../services/AuthenticationService';
import { VueSelect, VueSelectItem } from 'vue-select'
import BaseDropdown from '../../components/BaseDropdown.vue';
import BaseSelect from '../../components/BaseSelect.vue';
import socketioService from '../../socket/socketio.service';


export default {
  props: {
    data: {
      type: Object,
      default: () => { }

    }
  },
  components: {
    BaseTable,
    'vs-select': VueSelect,
    'vs-select-item': VueSelectItem,
    'base-select': BaseSelect,



  },
  data() {
    return {
      adminInput: {
        areaName: "",
        nickName: "",
        amountBet: "",
        betType: "",

      },
      dataUser: {
        differenceBreakBets: 0,
        priceFundOnOff: 1,
      },
      optionsArea: [
      { value: "ALL", label: "All Area" },
      { value: "BIT", label: "Bittrade" }
      ],
      optionsPer: [
        { value: false, label: "OFF" },
        { value: true, label: "ON" },
      ],
      differenceOver: '',
      breakBetsStartSecond: '',
      priceFundProfits: '',
      priceStopLoss: '',
      updatedFields: {},
      differenceBreakBets: 0,
      priceFundOnOff: 1,
      countDown: 0,
      volumeBuy: 0,
      volumeSell: 0,
      datanow: [],
      datamkt: [],
      itemsPerPage: 30,
      page: 1,
      breakType: "",
      isBet: true,
      turnPhase: "",
      betAmount: 0,
    }
  },
  computed: {
    pageCount() {

      return Math.ceil(this.datanow.length / this.itemsPerPage);

    },

    paginatedData() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.datanow.slice(start, end);
    },
    isBetComputed() {
      return this.isBet;
    },
  },
  methods: {
    searchUserEdit() {
      let obj = {
        areaName: this.adminInput.areaName,
        nickName: this.adminInput.nickName
      }
      // console.log(obj);
      AuthenticationService.searchBetAdmin(obj)
        .then((res) => {
        if (res.data.success) {
          this.adminInput.balance = res.data.data;
          // console.log(this.adminInput.balance);
        }
      })
    },
    disabledBet() {
      return (this.isBet = !this.isBet ? true : false);
    },
    updateData(page) {
      this.page = page;
    },
    getBreakBetsConfig() {
      AuthenticationService.getBreakBetsConfig()
        .then((res) => {
          if (res.data.success) {
            this.dataUser = res.data.data;

            return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: 'success',
              iconPack: 'feather',

            });
          } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
            this.$router.push('/altutit').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          } if (res.data.success == false && res.data.errorType == '') {
            return this.$vs.notification({
              text: "Không thể tìm kiếm thông tin !",
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            });
          }
        })
    },

    updateBreakBetsConfig() {
      const obj = {
        differenceBreakBets: this.dataUser.differenceBreakBets,
        differenceOver: parseFloat(this.dataUser.differenceOver),
        breakBetsStartSecond: parseFloat(this.dataUser.breakBetsStartSecond),
        priceFundOnOff: this.dataUser.priceFundOnOff,
        priceFundProfits: parseFloat(this.dataUser.priceFundProfits),
        priceStopLoss: parseFloat(this.dataUser.priceStopLoss),
        localChangeLow: parseFloat(this.dataUser.localChangeLow),
        localChangeHigh: parseFloat(this.dataUser.localChangeHigh),
        localResultLow: parseFloat(this.dataUser.localResultLow),
        localResultHigh: parseFloat(this.dataUser.localResultHigh)
      }

      // console.log(obj);
      AuthenticationService.updateBreakBetsConfig(obj)
        .then((resp) => {
          if (resp.data.success) {
            return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: 'success',
              iconPack: 'feather',

            });
          } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
            this.$router.push('/altutit').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          } else {
            return this.$vs.notification({
              text: "Không thể cập nhật thông tin !",
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            });
          }
        })
    },
    BetBuySell(betType) {
      let amountBet = this.betAmount.toString();
      amountBet = amountBet.replace(/[,]/g, '');
      amountBet = Number(amountBet);
      let accountType = "LIVE"
      betType = betType?.toUpperCase();
      let nickName = this.adminInput.nickName.toLocaleLowerCase();
      let areaName = this.adminInput.areaName;

      AuthenticationService.betAdmin({ amountBet, accountType, betType , nickName,areaName}).then(
        (response) => {
          if (response.data.success) {

            this.searchUserEdit();
            return this.$vs.notification({
              text: "Đặt lệnh thành công",
              color: "var(--primary-color)",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
              time: 2000,
            });
          }
          if (response.data.success == false && errorType == "insufficientBalance") {
            this.getUserInfo();
            return this.$vs.notification({
              text: "Số dư của bạn không đủ",
              color: "var(--primary-color)",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
              time: 2000,
            });
          }
        }
      );

    },

    SelectBetType(type) {
      socketioService.socket.emit("breakBetType", type);
    }
  },
  created() {

    socketioService.setupSocketConnection();
    socketioService.socket.emit("ADMIN_VOLUME_MONITOR_SUBCRIBE", {});


    socketioService.socket.on("ADMIN_LISTBET_MONITOR", (data) => {
      this.datanow = data.listBet.filter(a => !a.marketing && a.accountType == "LIVE")
        .sort((a, b) => {
          if (a.betType < b.betType) {
            return -1;
          }
          if (a.betType > b.betType) {
            return 1;
          }
          return 0;
        });
      const amountBetByType = this.datanow.reduce((acc, cur) => {
        if (cur.betType === "BUY") {
          acc.buy += Number.parseFloat(cur.amountBet);
        } else if (cur.betType === "SELL") {
          acc.sell += Number.parseFloat(cur.amountBet);
        }
        return acc;
      }, { buy: 0, sell: 0 });
      // console.log(amountBetByType);
    });

    socketioService.socket.on("ADMIN_VOLUME_MONITOR", (data) => {

      this.countDown = data.countdown + 1;
      this.volumeBuy = Number.parseFloat(data.volumeBuy);
      this.volumeSell = Number.parseFloat(data.volumeSell);
      this.breakType = data.breakType;
      this.session = Number(data.session);
      // console.log(this.session);
      if ( this.session % 2) {
        this.isBet = true;
        this.turnPhase = "ORDER";
        // console.log("TRUE", "ORDER");
      } else {
        this.isBet = false;
        this.turnPhase = "WAITING";
        // console.log("FALSE","ORDER");
      }
    });

    socketioService.socket.on("breakBetType", (data) => {
      if (data.success) {
        return this.$vs.notification({
          text: "Điều chỉnh thành công !",
          color: 'success',
          // iconPack: 'feather',

        });
      } else {
        return this.$vs.notification({
          text: "Không thể điều chỉnh !",
          color: 'success',
          // iconPack: 'feather',
        });
      }
    });
  },
  mounted() {
   const clickDelay = 300;
let lastClickTime = 0;

document.addEventListener('click', function (event) {
  const now = new Date().getTime();
  const timeSinceLastClick = now - lastClickTime;
  if (timeSinceLastClick <= clickDelay) {
    event.preventDefault();
  }
  lastClickTime = now;
}, false);


  }

}
</script>
<style>
.btn {
  padding: 11px 22.85px !important;
}

.green {
  color: green;
}

.red {
  color: red;
}

.timeCount {
  display: flex;
}

.form-control {
  margin-left: 2px;
  background: #1D253B;
}
.form-group {
  margin-left: 2px;
}
</style>
