<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card>
    <div class="col-md-5 pr-md-1">
      <base-input label="username" v-model="searchInput.username" name="item-username">
      </base-input>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsArea" label="areaName" v-model="searchInput.areaName" name="item-areaName">
      </base-select>
    </div>
    <base-button @click=getInfoAdmin()>Search</base-button>
    <div class="col-md-5 pr-md-1">
      <base-input label="password" v-model="dataAdmin.password" name="item-password">
      </base-input>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsPer" label="Edit Profile" v-model="dataAdmin.feature_profile"
        name="item-feature_profile">
      </base-select>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsPer" label="Check Verify" v-model="dataAdmin.feature_verify" name="item-areaName">
      </base-select>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsPer" label="Edit Bet" v-model="dataAdmin.feature_editBet" name="item-areaName">
      </base-select>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsPer" label="Check Deposit" v-model="dataAdmin.feature_deposit" name="item-areaName">
      </base-select>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsPer" label="Check Withdraw" v-model="dataAdmin.feature_withdraw" name="item-areaName">
      </base-select>
    </div>
    <div class="col-md-5 pr-md-1">
      <base-select :options="optionsPer" label="Check Volume & Commission" v-model="dataAdmin.feature_checkcom"
        name="item-areaName">
      </base-select>
    </div>
    <base-button @click=CreateAdmin()>Create</base-button>


    <base-button @click=setInfoAdmin()>Change</base-button>

      <div class="col-md-5 pr-md-1">
          <base-input label="Id" v-model="searchInput.nickName" name="item-username">
          </base-input>
           <base-select :options="optionsArea" label="areaName" v-model="searchInput.areaName" name="item-areaName">
        </base-select>
        </div>
    <div class="col-md-5 pr-md-1">
        <base-input label="Type" v-model="searchInput.type" name="item-username">
        </base-input>
      </div>
        <div class="col-md-5 pr-md-1">
          <base-input label="Title" v-model="searchInput.title" name="item-username">
          </base-input>
        </div>
          <div class="col-md-5 pr-md-1">
          <base-input label="Content" v-model="searchInput.content" name="item-username">
          </base-input>
        </div>

      <base-button @click=CreateNotify()>Create</base-button>

  </card>
</template>
<script>
import AuthenticationService from '../services/AuthenticationService';
import { VueSelect, VueSelectItem } from 'vue-select'
import BaseDropdown from '../../components/BaseDropdown.vue';
import BaseSelect from '../../components/BaseSelect.vue';


export default {
  props: {
    data: {
      type: Object,
      default: () => { }

    }
  },
  components: {
    'vs-select': VueSelect,
    'vs-select-item': VueSelectItem,
    'base-select': BaseSelect,



  },
  data() {
    return {


      optionsArea: [
      { value: "ALL", label: "All Area" },
      { value: "BIT", label: "Bittrade" }
      ],
      optionsPer: [
        { value: false, label: "OFF" },
        { value: true, label: "ON" },
      ],
      dataAdmin: {
        username: "",
        areaName: "",
        password: "",
        feature_profile: 0,
        feature_verify: 0,
        feature_editBet: 0,
        feature_deposit: 0,
        feature_withdraw: 0,
        feature_checkcom: 0,
      },
      searchInput: {
        areaName: '',
        username: '',
        nickName: '',
        id: 0,
        type: '',
        title: '',
        content: ''
      },



    }
  },
  methods: {

    CreateAdmin() {
      let obj = {
        username: this.searchInput.username,
        areaName: this.searchInput.areaName,
        password: this.dataAdmin.password,
      }
      AuthenticationService.createAdmin(obj)
        .then((res) => {
          if (res.data.success) {
            return this.$vs.notification({
              text: 'Đã tạo thông tin thành công ',
              color: 'success',
              iconPack: 'feather',

            });
          } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
            this.$router.push('/altutit').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          } if (res.data.success == false && res.data.errorType == '') {
            return this.$vs.notification({
              text: "Không thể tạo tài khoản !",
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            });
          }
        })
    },

    getInfoAdmin() {
      let obj = {
        username: this.searchInput.username,
        areaName: this.searchInput.areaName,
      }
      AuthenticationService.getAdminInfo(obj)
        .then((res) => {
          if (res.data.success) {
            this.dataAdmin = res.data.data;
            return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: 'success',
              iconPack: 'feather',

            });
          } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
            this.$router.push('/altutit').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          } if (res.data.success == false && res.data.errorType == 'UserNotFound') {
            return this.$vs.notification({
              text: "Không thể tìm kiếm thông tin !",
              color: 'danger',
              iconPack: 'feather',

            });
          }
        })
    },


    setInfoAdmin() {
      let obj = {
        username: this.searchInput.username,
        areaName: this.searchInput.areaName,
        password: this.dataAdmin.password,
        feature_profile: this.dataAdmin.feature_profile,
        feature_verify: this.dataAdmin.feature_verify,
        feature_editBet: this.dataAdmin.feature_editBet,
        feature_deposit: this.dataAdmin.feature_deposit,
        feature_withdraw: this.dataAdmin.feature_withdraw,
        feature_checkcom: this.dataAdmin.feature_checkcom
      }
      AuthenticationService.setInfoAdmin(obj)
        .then((res) => {
          if (res.data.success) {
            return this.$vs.notification({
              text: 'Đã cập nhập thông tin thành công ',
              color: 'success',
              iconPack: 'feather',

            });
          } if (res.data.success === false && res.data.errorType == "invalidAccessToken") {
            this.$router.push('/altutit').catch(() => { })
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("INFO");
            return this.$vs.notification({
              text: 'Phiên đăng nhập đã hết hạn! ',
              color: '#4B0082',
            });
          } if (res.data.success == false && res.data.errorType == '') {
            return this.$vs.notification({
              text: "Không thể đổi thông tin !",
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            });
          }
        })
    },
    CreateNotify() {
      let obj = {
        nickName: this.searchInput.nickName,
        areaName: this.searchInput.areaName,
        type: this.searchInput.type,
        title: this.searchInput.title,
        content: this.searchInput.content
      }
      AuthenticationService.CreateNotify(obj)
        .then((res) => {
          if (res.data.success) {
            return this.$vs.notification({
              text: 'Đã tạo thông báo thành công ',
              color: 'success',
              iconPack: 'feather',

            });
          }
      })
    }

  },
  computed: {

  },
}
</script>
<style></style>
